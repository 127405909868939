:root {
  --strawberry-background-color: #f5f7fa;
  --strawberry-text-color: #000000;
  --strawberry-accent-color: #005dc7;
  --strawberry-header-background-color: #ffffff;
  --strawberry-footer-background-color: #000000;
  --strawberry-footer-text-color: #ffffff;
  --strawberry-text-font: "SplineSans", "Helvetica", "Arial", serif;
  --strawberry-title-font: "Gelasio", "Georgia", "Times New Roman", serif;
  --strawberry-sans-font: Arial, "Helvetica Neue", Helvetica, sans-serif;
  --swiper-theme-color: #000000;
  --strawberry-magnifier-color: #000000;
  --strawberry-bars-color: #000000;
  --strawberry-divider-color: rgba(0, 0, 0, 1);
  --strawberry-sticky-top: 35px;
}

/*@media (prefers-color-scheme: dark) {
    :root {
        --strawberry-background-color: #11100a;
        --strawberry-text-color: rgba(255, 255, 255, .9);
        --strawberry-divider-color: rgba(255, 255, 255, .2);
        --strawberry-accent-color: rgba(255, 255, 255, .9);
    }
}*/
.dark {
  --strawberry-background-color: #0e1113;
  --strawberry-header-background-color: #000000;
  --strawberry-text-color: rgba(255, 255, 255, .9);
  --strawberry-divider-color: rgba(255, 255, 255, .2);
  --strawberry-accent-color: rgba(255, 255, 255, .9);
}

/* Colors */
body {
  font-family: var(--strawberry-text-font);
  font-weight: 500;
  background-color: var(--strawberry-background-color);
  color: var(--strawberry-text-color);
  line-height: 1.2;
  margin: 0;
  padding: 15px;
}

.error__wrapper {
  max-width: 700px;
  min-height: 90dvh;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.error__wrapper .headline {
  font-size: 1.3125rem;
  font-weight: bold;
  text-align: center;
}
.error__logo {
  max-width: fit-content;
  margin: 0 auto;
}
.error__content {
  text-align: center;
}
.error__content h1 {
  font-size: 10rem;
  margin: 3rem 0;
}
.error__search form {
  display: flex;
  justify-content: center;
}
.error__search .text {
  flex: 1;
  background-color: white;
  color: var(--strawberry-text-color);
  font-size: 1.125rem;
  line-height: 1.3125rem;
}
.error__search .button {
  background-color: var(--strawberry-text-color);
  color: var(--strawberry-background-color);
  font-weight: bold;
  font-size: 0.875rem;
  line-height: 1.3125rem;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 5px 20px;
}
.error__search .text {
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
}
.error__articles {
  display: flex;
  flex-direction: column;
  gap: 30px;
}
.error__articles .article {
  background-color: #fff;
  border-radius: 10px;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  min-height: 100px;
}
.error__articles .article__title {
  font-weight: bold;
  font-size: 1.125rem;
}
.error__articles .article__title span {
  opacity: 0.5;
}
.error__articles .article__title a {
  color: black;
  text-decoration: none;
}
.error__articles .article__excerpt {
  color: rgba(0, 0, 0, 0.8);
  display: -webkit-box !important;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
@supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
  .error__articles .article__excerpt {
    -webkit-line-clamp: 4 !important;
  }
}
.error__articles .article__excerpt > * {
  display: inline;
}
.error__footer {
  margin-top: auto;
  font-size: 14px;
  text-align: center;
}