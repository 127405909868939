@import "base/variables";
@import "base/mixins";
body {
    font-family: var(--strawberry-text-font);
    font-weight: 500;
    background-color: var(--strawberry-background-color);
    color: var(--strawberry-text-color);
    line-height: 1.2;
    margin: 0;
    padding: 15px;
}
.error {
    &__wrapper {
        max-width: 700px;
        min-height: 90dvh;
        margin: 0 auto;
        display: flex;
        flex-direction: column;
        gap: 30px;

        .headline {
            font-size: rem(21);
            font-weight: bold;
            text-align: center;
        }
    }

    &__logo {
        max-width: fit-content;
        margin: 0 auto;
    }

    &__content {
        text-align: center;
        h1 {
            font-size: 10rem;
            margin: 3rem 0;
        }
    }

    &__search {
        form {
            display: flex;
            justify-content: center;
        }

        .text {
            flex: 1;
            background-color: white;
            color: var(--strawberry-text-color);
            font-size: rem(18);
            line-height: rem(21);
        }

        .button {
            background-color: var(--strawberry-text-color);
            color: var(--strawberry-background-color);
            font-weight: bold;
            font-size: rem(14);
            line-height: rem(21);
            border: 1px solid rgba(0,0,0, .3);
            padding: 5px 20px;
        }

        .text {
            padding: 5px 10px;
            border: 1px solid rgba(0,0,0, .3);
        }
    }

    &__articles {
        display: flex;
        flex-direction: column;
        gap: 30px;

        .article {
            background-color: #fff;
            border-radius: 10px;
            padding: 20px;
            display: flex;
            flex-direction: column;
            gap: 10px;
            min-height: 100px;

            &__title {
                font-weight: bold;
                font-size: rem(18);
                span {
                    opacity: .5;
                }
                a {
                    color: black;
                    text-decoration: none;
                }
            }

            &__excerpt {
                color: rgba(0,0,0, .8);
                @include clamp_lines(3);
            }
        }
    }

    &__footer {
        margin-top: auto;
        font-size: 14px;
        text-align: center;
    }
}