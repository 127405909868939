:root {
    --strawberry-background-color: #f5f7fa;
    --strawberry-text-color: #000000;
    --strawberry-accent-color: #005dc7;

    --strawberry-header-background-color: #ffffff;

    --strawberry-footer-background-color: #000000;
    --strawberry-footer-text-color: #ffffff;

    --strawberry-text-font: "SplineSans", "Helvetica", "Arial", serif;
    --strawberry-title-font: "Gelasio", "Georgia", "Times New Roman", serif;
    --strawberry-sans-font: Arial, "Helvetica Neue", Helvetica, sans-serif;

    --swiper-theme-color: #000000;

    --strawberry-magnifier-color: #000000;
    --strawberry-bars-color: #000000;

    --strawberry-divider-color: rgba(0, 0, 0, 1);

    --strawberry-sticky-top: 35px;
}

/*@media (prefers-color-scheme: dark) {
    :root {
        --strawberry-background-color: #11100a;
        --strawberry-text-color: rgba(255, 255, 255, .9);
        --strawberry-divider-color: rgba(255, 255, 255, .2);
        --strawberry-accent-color: rgba(255, 255, 255, .9);
    }
}*/

.dark {
    --strawberry-background-color: #0e1113;
    --strawberry-header-background-color: #000000;
    --strawberry-text-color: rgba(255, 255, 255, .9);
    --strawberry-divider-color: rgba(255, 255, 255, .2);
    --strawberry-accent-color: rgba(255, 255, 255, .9);
}

$phone-width: 768;
$desktop-width: 970;
$large-width: 1290;

$phone: "(max-width: #{$phone-width}px)";
$tablet: "(min-width: #{$phone-width + 1}px)";
$tablet-o: "(min-width:  #{$phone-width + 1}px) and (max-width:  #{$desktop-width}px)"; // Only for Tablet
$tablet-b: "(max-width:  #{$desktop-width}px)"; // Tablet and Below
$desktop: "(min-width: #{$desktop-width + 1}px)";
$desktop-o: "(min-width: #{$desktop-width + 1}px) and (max-width: #{$large-width - 1}px)"; // Only for Desktop small screens
$desktop-b: "(max-width: #{$large-width - 1}px)"; // Desktop small screens and Below
$large: "(min-width: #{$large-width}px)";

$grid-gap: 20;
$gutter: calc($grid-gap / 2);

$grid-gaps: (
        "xs": 3em,
        "sm": 3em,
        "smo": 3em,
        "smb": 3em,
        "md": 3em,
        "mdo": 3em,
        "mdb": 3em,
        "lg": 3em,
);

$grid-sizes: (
        "xs": $phone,
        "sm": $tablet,
        "md": $desktop,
        "lg": $large,
);

$percentSizes: (
        "25p": 25,
        "50p": 50,
        "33p": 33,
        "40p": 40,
        "60p": 60,
        "75p": 75,
        "80p": 80,
        "90p": 90,
        "100p": 100,
);

$sizes: (
        "xs": $phone,
        "sm": $tablet,
        "smo": $tablet-o,
        "smb": $tablet-b,
        "md": $desktop,
        "mdo": $desktop-o,
        "mdb": $desktop-b,
        "lg": $large,
);

$locations: "", "top", "right", "bottom", "left";
$alignments: "left", "right", "center", "justify";

$fontSizes: 11, 12, 13, 14, 15, 16, 18, 20, 21, 23, 26, 30, 35, 40, 45, 50, 80;
$fontWeights: 100, 300, 400, 500, 600, 700, 800, 900, 'bold', 'bolder';

$fontFamilies: (
        "main" : var(--strawberry-text-font),
        "titles" : var(--strawberry-title-font),
        "sans": "Arial, Helvetica, sans, sans-serif",
);

// Mobile navigation bars
$bars-width: 22px;
$bars-height: 3px;

/* Colors */
$white: #ffffff;
$black: #000000;
$pink: #E91E63;
$grey: #d7d7d7;
$gold: #f5efeb;
$dark: #0d1013;
$green: #22c55e;
$orange: #f79818;
$navy: #172331;
$denim: #26376f;
$slate: #122832;
$blue: #005dc7;
$aqua: #8ecae6;
$yellow: #ffd203;
$violet: #210037;
$purple: #a91d7e;
$red: #ed1b26;
$orange: #F84C00;
$brown: #964B00;
$facebook: #1877F2;
$twitter: #1DA1F2;
$youtube: #FF0000;
$instagram: #E4405F;
$whatsapp: #25D366;
$linkedin: #0077B5;

$colors: (
                ("white", $white),
                ("black", $black),
                ("pink", $pink),
                ("grey", $grey),
                ("gold", $gold),
                ("dark", $dark),
                ("green", $green),
                ("orange", $orange),
                ("navy", $navy),
                ("denim", $denim),
                ("slate", $slate),
                ("blue", $blue),
                ("aqua", $aqua),
                ("yellow", $yellow),
                ("violet", $violet),
                ("purple", $purple),
                ("red", $red),
                ("orange", $orange),
                ("brown", $brown),
                ("facebook", $facebook),
                ("instagram", $instagram),
                ("linkedin", $linkedin),
                ("twitter", $twitter),
                ("youtube", $youtube),
                ("whatsapp", $whatsapp),
);

//$modalbox-background: linear-gradient(142deg, rgba(9, 0, 36, .9) 0%, rgba(154, 0, 255, .9) 100%);
$modalbox-background: $black;
$modalbox-color:#ffffff ;