@mixin reset() {
    margin: 0;
    padding: 0;
    list-style-type: none;
    border: 0;
}

@function rem($value) {
    $remValue: calc($value / 16) + rem;
    @return $remValue;
}

@mixin overlay($parent) {
    position: relative;
    overflow: hidden;
    &:after {
        content: " ";
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        display: block;
        z-index: 1;
        background: rgb(0, 0, 0);
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.4) 0%, rgba(0, 0, 0, .8) 100%);
    }

    #{$parent}__content {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        top: 0;
        padding: 16px;
        z-index: 8;
        width: auto;
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        @media #{$tablet-b}{
            padding: 10px;
        }
    }

    #{$parent}__title {
        color: #fff;
        margin: 0;

        a {
            color: #fff;
        }

    }

    .permalink {
        position: absolute;
        z-index: 999;
        display: block;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 100%;
    }
}

@mixin fancyScrollbars($barColor, $trackColor) {
    &::-webkit-scrollbar {
        width: 7px;
        height: 7px;
    }

    &::-webkit-scrollbar-thumb {
        background: $barColor;
        border-radius: 7px;
    }

    &::-webkit-scrollbar-track {
        background: $trackColor;
    }
}

@mixin clamp_lines($lines) {
    display: -webkit-box !important;
    -webkit-line-clamp: $lines;
    -webkit-box-orient: vertical;
    overflow: hidden;
    @supports (hanging-punctuation: first) and (font: -apple-system-body) and (-webkit-appearance: none) {
        -webkit-line-clamp: $lines + 1 !important;
    }
    > * {
        display: inline;
    }
}

@mixin arrow($position) {
    position: relative;
    &:after {
        content: "";
        display: block;
        width: 0;
        background-color: transparent;
        border-color: transparent transparent transparent $red;
        border-style: solid;
        border-width: 5px 7px;
        position: absolute;
        top: 50%;

        @if ($position == 'left') {
            left: 0;
            transform: translateY(-50%);
        }
        @if ($position == 'right') {
            right: 0;
            transform: translate(15px, -50%);
        }

        @media #{$phone}{
            border-width: 3px 4px;
            transform: translate(3px, -50%);
        }
    }
}

@function color-opacity($color, $opacity) {
    $remValue: ($value / 16) + rem;
    @return $remValue;
}

@mixin absolute-vertical-center() {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
}

@mixin circle($diagonal) {
    display: inline-flex;
    width: #{$diagonal}px;
    height: #{$diagonal}px;
    align-items: center;
    justify-content: center;
    border-radius: 100%;
    overflow: hidden;
}

@mixin fill() {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 999;
}